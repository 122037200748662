import {DataObject} from 'o365.modules.DataObject.ts';

declare module "o365.modules.DataObject.ts" {
    interface DataObject {
        grouping: Grouping;
        enableGrouping:Function
    }
}

DataObject.prototype.enableGrouping = function(){
    this.grouping = new Grouping(this);
}
// a
enum GroupingMode {
    GroupBy,
    Hierarchy
}



class Grouping {
    private groupingMode: GroupingMode;
    enabled = false;
    private deepestLevel = 0;
    private _dataObject: DataObject;
    private _groupBy = [];
    private formatFunction: (row: any) => Array<string>;
    private collapsedRows = {};
    uniqueField: string;

    transformFn?: (pItem: any) => void;

    get groupBy() {
        return this._groupBy;
    }
    set groupBy(value) {
        this._groupBy = value ?? [];
        this.formatFunction = (item) => {
            return [...value.map(field => item[field]), item[this.uniqueField]];
        };
        if (this._groupBy.length > 0) {
            this.groupingMode = GroupingMode.GroupBy;
            this.enable();
        } else {
            this.disable();
        }
    }

    get rowCount() {
        return this._dataObject.data.reduce((count, row: any) => {
            if (row.o_groupLevel === 0) {
                return count + (row.o_groupCount ?? 0);
            } else {
                return count;
            }
        }, 0);
    }

    constructor(pDataObject: DataObject) {
        this._dataObject = pDataObject;
        this.uniqueField = 'PrimKey';


    }

    enable() {
        if (!this.enabled) {
            this._dataObject.dataHandler.groupFormatFunction = this.groupFormatFunction.bind(this);
            this.enabled = true;
        }
    }

    disable() {
        if (this.enabled) {
            this._dataObject.dataHandler.groupFormatFunction = null;
            this.enabled = false;
        }
    }

    setGroupBy(pGroupBy: Array<string>) {
        this.groupBy = pGroupBy;
    }

    setFormatFunction(formatFunction:(row: any) => Array<string>) {
        this.formatFunction = formatFunction;
        if (formatFunction) {
            this.groupingMode = GroupingMode.Hierarchy;
            this.enable();
        } else {
            this.disable();
        }
    }

    groupFormatFunction(pData: Array<any>) {
        if (!this._dataObject.clientSideFiltering || !this.collapsedRows) {
            this.collapsedRows = {};
        }
        if (this.transformFn) {
            pData.forEach(item => this.transformFn!(item));
        }
        const returnData = [];

        const topLevel = [];
        const root = {};

        const createTempRow = (level:any, dataPath:any) => {
            const temp = <any>{
                o_groupLevel: level,
                o_groupHeaderRow: true,
                o_groupKey: dataPath
            };
            if (this.groupingMode === GroupingMode.Hierarchy) {
                temp.o_groupValue = dataPath[level];
            }
            return temp;
        }

        for (let i = 0; i < pData.length; i++) {
            let vRow = pData[i];

            const dataPath = this.formatFunction(vRow) ?? [];

            if (this.groupingMode === GroupingMode.Hierarchy) {
                vRow.o_groupValue = dataPath[dataPath.length - 1];
            }
            vRow.o_groupKey = dataPath;
            vRow.o_groupLevel = vRow.o_groupKey.length - 1;
            if (this.deepestLevel < vRow.o_groupLevel) { this.deepestLevel = vRow.o_groupLevel; }

            if (dataPath.length > 1) {
                const rootKey = JSON.stringify(dataPath.slice(0, 1));
                let detailsScope;
                if (root[rootKey]) {
                    detailsScope = root[rootKey].details;
                } else {
                    root[rootKey] = {
                        item: createTempRow(0, dataPath.slice(0, 1)),
                        details: {},
                    };
                    if (this.groupingMode === GroupingMode.GroupBy) {
                        const field = this.groupBy[0];
                        root[rootKey].item[field] = vRow[field];
                    }
                    detailsScope = root[rootKey].details;
                    topLevel.push(rootKey);
                }
                for (let index = 2; index <= dataPath.length; index++) {
                    const key = JSON.stringify(dataPath.slice(0, index));

                    if (index === dataPath.length) {
                        if (detailsScope[key]) {
                            detailsScope[key].item = vRow;
                        } else {
                            const itemIndex = Object.keys(detailsScope).length;
                            detailsScope[key] = {
                                item: vRow,
                                details: {},
                                index: itemIndex
                            }
                        }
                        continue;
                    }

                    if (!detailsScope[key]) {
                        const itemIndex = Object.keys(detailsScope).length;
                        detailsScope[key] = {
                            item: createTempRow(index - 1, dataPath.slice(0, index)),
                            details: {},
                            index: itemIndex
                        };
                        if (this.groupingMode === GroupingMode.GroupBy) {
                            this.groupBy.slice(0, index).forEach(field => {
                                detailsScope[key].item[field] = vRow[field];
                            });
                        }
                    }
                    detailsScope = detailsScope[key].details;
                }
            } else {
                const key = JSON.stringify(dataPath);
                if (root[key]) {
                    root[key].item = vRow;
                } else {
                    root[key] = {
                        item: vRow,
                        details: {},
                    };
                    topLevel.push(key);
                }

            }
        }

        const pushItem = (row:any, parentKey = null, detailsCount = 0) => {
            const itemHasDetails = Object.keys(row.details).length > 0;
            if (itemHasDetails) {
                row.item.o_groupHasDetails = true;
            }

            const index = returnData.length;
            const key = JSON.stringify(row.item.o_groupKey);

            if (!parentKey) {
                returnData.push(row.item);
            } else {
                this.collapsedRows[parentKey].push(row.item);
            }

            if (itemHasDetails) {
                const subItems = Object.values(row.details);

                if (this._dataObject.clientSideFiltering && this.collapsedRows[key]) {
                    row.item.o_groupCollapsed = true;
                    this.collapsedRows[key] = [];
                    subItems.forEach((item) => {
                        detailsCount += pushItem(item, key);
                    });
                } else {
                    subItems.forEach((item) => {
                        detailsCount += pushItem(item, parentKey);
                    });
                }
                if (!parentKey) {
                    returnData[index].o_groupCount = detailsCount;
                } else {
                    row.item.o_groupCount = detailsCount;
                }
            }

            if (!row.item.o_groupHeaderRow) {
                detailsCount++;
            }
            return detailsCount;
        }

        topLevel.forEach(key => {
            const row = root[key];
            pushItem(row);
        });

        return returnData;
    }

    groupFormatFunction2(pData: Array<any>) {
        if (!this._dataObject.clientSideFiltering || !this.collapsedRows) {
            this.collapsedRows = {};
        }
        const returnData = [];

        const topLevel = [];
        const root = {};

        const createTempRow = (level, dataPath) => {
            const temp = <any>{
                o_groupLevel: level,
                o_groupHeaderRow: true,
                o_groupKey: dataPath
            };
            if (this.groupingMode === GroupingMode.Hierarchy) {
                temp.o_groupValue = dataPath[level];
            }
            return temp;
        }

        for (let i = 0; i < pData.length; i++) {
            let vRow = pData[i];

            const dataPath = this.formatFunction(vRow) ?? [];

            if (this.groupingMode === GroupingMode.Hierarchy) {
                vRow.o_groupValue = dataPath[dataPath.length - 1];
            }
            vRow.o_groupKey = dataPath;
            vRow.o_groupLevel = vRow.o_groupKey.length - 1;
            if (this.deepestLevel < vRow.o_groupLevel) { this.deepestLevel = vRow.o_groupLevel; }

            if (dataPath.length > 1) {
                const rootKey = JSON.stringify(dataPath.slice(0, 1));
                let detailsScope;
                if (root[rootKey]) {
                    detailsScope = root[rootKey].details;
                } else {
                    root[rootKey] = {
                        item: createTempRow(0, dataPath.slice(0, 1)),
                        details: {},
                    };
                    if (this.groupingMode === GroupingMode.GroupBy) {
                        const field = this.groupBy[0];
                        root[rootKey].item[field] = vRow[field];
                    }
                    detailsScope = root[rootKey].details;
                    topLevel.push(rootKey);
                }
                for (let index = 2; index <= dataPath.length; index++) {
                    const key = JSON.stringify(dataPath.slice(0, index));

                    if (index === dataPath.length) {
                        if (detailsScope[key]) {
                            detailsScope[key].item = vRow;
                        } else {
                            const itemIndex = Object.keys(detailsScope).length;
                            detailsScope[key] = {
                                item: vRow,
                                details: {},
                                index: itemIndex
                            }
                        }
                        continue;
                    }

                    if (!detailsScope[key]) {
                        const itemIndex = Object.keys(detailsScope).length;
                        detailsScope[key] = {
                            item: createTempRow(index - 1, dataPath.slice(0, index)),
                            details: {},
                            index: itemIndex
                        };
                        if (this.groupingMode === GroupingMode.GroupBy) {
                            this.groupBy.slice(0, index).forEach(field => {
                                detailsScope[key].item[field] = vRow[field];
                            });
                        }
                    }
                    detailsScope = detailsScope[key].details;
                }
            } else {
                const key = JSON.stringify(dataPath);
                if (root[key]) {
                    root[key].item = vRow;
                } else {
                    root[key] = {
                        item: vRow,
                        details: {},
                    };
                    topLevel.push(key);
                }

            }
        }

        const pushItem = (row, parentKey = null, detailsCount = 0) => {
            const itemHasDetails = Object.keys(row.details).length > 0;
            if (itemHasDetails) {
                row.item.o_groupHasDetails = true;
            }

            const index = returnData.length;
            const key = JSON.stringify(row.item.o_groupKey);

            if (!parentKey) {
                returnData.push(row.item);
            } else {
                this.collapsedRows[parentKey].push(row.item);
            }

            if (itemHasDetails) {
                const subItems = Object.values(row.details);

                if (this._dataObject.clientSideFiltering && this.collapsedRows[key]) {
                    row.item.o_groupCollapsed = true;
                    this.collapsedRows[key] = [];
                    subItems.forEach((item) => {
                        detailsCount += pushItem(item, key);
                    });
                } else {
                    subItems.forEach((item) => {
                        detailsCount += pushItem(item, parentKey);
                    });
                }
                if (!parentKey) {
                    returnData[index].o_groupCount = detailsCount;
                } else {
                    row.item.o_groupCount = detailsCount;
                }
            }

            detailsCount++;
            return detailsCount;
        }

        topLevel.forEach(key => {
            const row = root[key];
            pushItem(row);
        });

        return returnData;
    }

    getDetailsRange(pRow, pIndex) {
        const currentDataPath = JSON.stringify(pRow.o_groupKey);
        const startIndex = (pIndex ?? pRow.index) + 1;

        const data = <Array<any>>this._dataObject.data;
        let endIndex = startIndex;
        let testPath = JSON.stringify(data[endIndex].o_groupKey.slice(0, pRow.o_groupLevel + 1));
        while (endIndex < data.length && currentDataPath === testPath) {
            endIndex++;
            if (data[endIndex]) {
                testPath = JSON.stringify(data[endIndex].o_groupKey.slice(0, pRow.o_groupLevel + 1));
            }
        }
        endIndex--;

        return [startIndex, endIndex];
    }

    collapseAll() {
        for (let i = this.deepestLevel; i >= 0; i--) {
            this.collapseLevel(i);
        }
    };

    collapseLevel(level) {
        this._dataObject.data.forEach((row, index) => {
            if (row.o_groupHasDetails && row.o_groupLevel === level) {
                this.collapse(row, index);
            }
        });
    }

    collapse(pRow, pIndex) {
        if (!this.enabled) { return; }

        if (typeof pRow === 'number') {
            pRow = this._dataObject.data[pRow];
        } else if (!pRow) {
            pRow = this._dataObject.current;
        }

        if (!pRow.o_groupCollapsed && pRow.o_groupHasDetails) {
            pRow.o_groupCollapsed = true;
            const [startIndex, endIndex] = this.getDetailsRange(pRow, pIndex);
            if (!this.collapsedRows) { this.collapsedRows = {}; }
            const key = JSON.stringify(pRow.o_groupKey);

            if (this._dataObject.clientSideFiltering) {
                this.collapsedRows[key] = this._dataObject.data.slice(startIndex, endIndex + 1);
            } else {
                this.collapsedRows[key] = [this._dataObject.data[startIndex].index, this._dataObject.data[endIndex].index];
            }

            this._dataObject.data.splice(startIndex, endIndex - startIndex + 1);
            // this._dataObject.setCurrentIndex(pIndex);
        }
    }

    expandAll(options: {
        conditionFunction?: (item: any) => boolean,
    }) {
        let index = 0;
        while (index < this._dataObject.data.length) {
            const row = this._dataObject.data[index];
            if (row.o_groupCollapsed && row.o_groupHasDetails) {
                const canExpand = options?.conditionFunction ? options.conditionFunction(row) : true;
                if (canExpand) {
                    this.expand(row, index);
                }
            }
            index++;
        }
    }


    expand(pRow, pIndex) {
        if (!this.enabled) { return; }

        if (typeof pRow === 'number') {
            pRow = this._dataObject.data[pRow];
        } else if (!pRow) {
            pRow = this._dataObject.current;
        }

        if (pRow.o_groupCollapsed && pRow.o_groupHasDetails) {
            const key = JSON.stringify(pRow.o_groupKey);
            if (!this.collapsedRows[key]) {
                console.warn(`Row is missing collapsed indexes ${pRow}`);
                return;
            }
            pRow.o_groupCollapsed = false;

            let storageSlice;
            if (this._dataObject.clientSideFiltering) {
                storageSlice = this.collapsedRows[key];
            } else {
                const [startIndex, endIndex] = this.collapsedRows[key];
                storageSlice = this._dataObject.storage.data.slice(startIndex, endIndex + 1).filter(row => {
                    const groupKey = JSON.stringify(row['o_groupKey'].slice(0, row['o_groupLevel']));
                    return key === groupKey || !this.collapsedRows.hasOwnProperty(groupKey);
                });
            }
            this._dataObject.data.splice(pIndex + 1, 0, ...storageSlice);
            delete this.collapsedRows[key];
        }
    }

    //--------------------------------------------
    // Server grouping
    //--------------------------------------------

    private _cache = {};

    private getGroupByFields() {
        
    }

    async loadGroups() {
        const options = this._dataObject.recordSource.getOptions();


        options.whereClause = options.whereClause
            ? `(${options.whereClause}) AND (${this['_parentField']} IS NULL)`
            : `${this['_parentField']} IS NULL`;

        const data = <any[]>await this._dataObject.dataHandler.request('retrieve', options);

        const storageData = this._dataObject.storage.setItems(data, true);
        storageData[0].current = true;
        this._dataObject.setData(storageData, true);

        return storageData;
    }

    loadGroup(item: any) {
        
    }
}
